.mobile-verify-container {
  width: 60%;
  display: block;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 50px;
  border-radius: 20px;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media screen and (max-width: 992px) {
  .mobile-verify-container {
    width: 100%;
    border-radius: 0;
    padding: 50px 20px;
  }
}
