.terms-container {
  padding: 50px;
}
.terms-container h4 {
  text-align: center;
}
.terms-container h5 {
  margin-top: 30px;
  color: #066759;
  font-weight: 600;
  position: relative;
  text-transform: uppercase;
}
