.testimonial-container {
  padding: 10px;
}
.testimonial {
  padding: 20px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
  min-height: 250px;
  background-color: #fff;
}
.t-name-img {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}
.t-name-img img {
  width: 40px;
  border-radius: 100px;
}
.testimonial-container span {
}
