.admin-dashboard-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  color: #000;
  gap: 16px;
}
.admin-dashboard-card {
  width: 24%;
  background-color: #fff;
  display: flex;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    background-color: #eee;
  }
  &:hover .icon-container .icon {
    scale: 1.2;
  }
}
.admin-dashboard-card .details {
  width: 70%;
}

.admin-dashboard-card .details h1 {
  font-size: 60px;
  margin: 0;
}
.admin-dashboard-card .details span {
  font-size: 16px;
}
.admin-dashboard-card .icon-container {
  width: 30%;
}
.icon-container .icon {
  font-size: 50px;
  transition: 0.3s all ease;
}
