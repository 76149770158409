.pdf-container {
  position: relative;
}

.watermark {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-10deg);
  font-size: 70px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.07);
  text-align: center;
}

@media screen and (max-width: 992px) {
  .pdf-container img {
    width: 70%;
  }
}
