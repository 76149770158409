.verification-page-container {
  background-color: #f7f7f7;
}
.verification {
  width: 50%;
  display: block;
  margin: 0 auto;
  background-color: #fff;
  padding: 50px 20px;
  min-height: 72vh;
}
.email-verification {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 10px;
}
.email-verification:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.email-verification.active {
  background-color: rgba(0, 0, 0, 0.1);
}
.mobile-verification {
  display: block;
  margin: 0 auto;
}
.id-proof-input {
  border: 2px solid #26bd68;
  padding: 6px;
  margin-right: 4px;
}
@media screen and (max-width: 992px) {
  .verification {
    width: 100%;
  }
}
