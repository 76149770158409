.blogpage-container {
  padding: 50px 20px;
  background-color: #fff;
}
.blogpage-blog {
  display: block;
  margin: 0 auto;
  width: 50%;
}
.blogpage-blog img {
  width: 100%;
  display: block;
  margin: 0 auto;
  margin-bottom: 25px;
}
.blogpage-blog h1 {
  font-size: 30px;
  font-weight: 600;
}
.blogpage-blog p {
  white-space: pre-wrap;
}

@media screen and (max-width: 992px) {
  .blogpage-blog {
    display: block;
    margin: 0 auto;
    width: 100%;
  }
  .blogpage-blog img {
    width: 100%;
    margin-bottom: 20px;
  }
  .blogpage-blog h1 {
    font-size: 22px;
    font-weight: 600;
  }
  .blogpage-blog p {
    font-size: 14px;
  }
}
