/* ==================== RECEIVE CSS  */
.inbox-container {
  width: 60%;
  display: block;
  margin: 0 auto;
  background-color: white;
  padding: 50px 10px;
  min-height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.inbox-all-users {
  width: 60%;
  display: block;
  margin: 0 auto;
}
.inbox-user-card {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
}
.inbox-user-img {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inbox-user-img img {
  width: 120px;
  height: 120px;
  border-radius: 100%;
  object-fit: cover;
}
.inbox-user-content {
  width: 50%;
  padding: 20px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.inbox-action-btn {
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.inbox-action-btn .icon {
  font-size: 40px;
  cursor: pointer;
  scale: 1;
  transition: 0.3s all ease;
}
.inbox-action-btn .icon:hover {
  scale: 1.1;
}
.inbox-action-btn-contact {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.inbox-action-btn-contact .icon {
  font-size: 50px;
  background-color: #eee;
  border-radius: 100%;
  padding: 10px;
}
.inbox-action-btn-accepted {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.inbox-action-btn-accepted.active {
  background-color: #ccffe2;
}
.inbox-action-btn-accepted .call-btn,
.inbox-action-btn-accepted .whatsapp-btn {
  font-size: 50px;
  padding: 10px;
  margin: 0;
}

.inbox-upgrade-btn {
  display: block;
  margin: 0 auto;
  background-color: #fff;
  outline: transparent;
  border: transparent;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
  padding: 5px 15px;
  border-radius: 15px;
  font-size: 12px;
}
/* ==================== RECEIVE CSS ENDS  */
@media screen and (max-width: 992px) {
  .inbox-container {
    width: 100%;
    padding-bottom: 100px;
  }
  .inbox-all-users {
    width: 100%;
  }
  .inbox-user-img {
    width: 30%;
  }
  .inbox-user-img img {
    width: 100%;
  }
  .inbox-user-content {
    width: 70%;
    border-right: 0px;
  }
  .inbox-action-btn {
    width: 100%;
    padding: 10px;
    flex-direction: row;
    background-color: #f7f7f7;
  }

  .inbox-action-btn-contact {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
}
