.about-container {
  padding: 50px 0;
  background-color: #def4e9;
}
.about-img {
  /* background-image: url("../svg/about2.svg"); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 45vh;
  width: 50%;
  display: block;
  margin: 0 auto;
  position: relative;
  @media screen and (max-width: 992px) {
    background-image: none;
  }
}

@media screen and (max-width: 992px) {
  .about-img {
    width: 100%;
    height: 60vh;
  }
}
@media screen and (max-width: 768px) {
  .about-img {
    width: 100%;
    height: 40vh;
  }
}
@media screen and (max-width: 640px) {
  .about-img {
    width: 100%;
    height: 30vh;
  }
}
@media screen and (max-width: 420px) {
  .about-img {
    width: 100%;
    height: 20vh;
  }
}
@media screen and (max-width: 375px) {
  .about-img {
    width: 100%;
    height: 20vh;
  }
}
