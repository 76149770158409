.profile-slider-container {
  padding: 50px;
  padding-bottom: 80px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.user-card {
  gap: 10px;
  padding: 10px;
}
.user-card:hover {
  cursor: pointer;
}
.user-card-img {
  overflow: hidden;
  position: relative;
}
.user-card-img img {
  filter: blur(5px);
}
.user-card-img .icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}
.user {
  display: flex;
  flex-direction: column;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.4);
}
.user-card img {
  width: 100%;
}
.user-content {
  padding: 0 10px;
  min-height: 180px;
}
.user-content p {
  font-size: 14px;
}
.slick-next:before,
.slick-prev:before {
  color: #26bd68 !important;
  font-size: 25px;
}
@media screen and (max-width: 880px) {
  .user-content {
    min-height: 150px;
  }
}
