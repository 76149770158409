.admin-payment-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  color: #000;
  gap: 16px;
}
.payment-history-container {
  margin-top: 20px;
  background-color: #fff;
  color: #000;
  padding: 10px;
}
.payment-list {
  display: flex;
  justify-content: space-evenly;
}
