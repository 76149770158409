.blog-container {
  padding: 10px;
}
.blog {
  width: 100%;
  background-color: #fff;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  overflow: hidden;

  &:hover .blog-img img {
    scale: 1.05;
  }
}
.blog-img {
  height: 200px;
  overflow: hidden;
}
.blog-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.3s all ease;
}
.blog-content {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding: 20px;
}
.blog-content h6 {
  font-weight: 600;
}
