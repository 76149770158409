.admin-send-profile-container {
  background-color: #fff;
  color: #000;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.admin-send-profile-container .filter-container {
  width: 20%;
}
.admin-send-profile-container .male-container {
  width: 40%;
}
.admin-send-profile-container .female-container {
  width: 40%;
}
.selection-male-female {
  background-color: #fff;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000;
}
.list-container {
  background-color: #fff;
}
