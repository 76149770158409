.today-matches-container {
  position: relative;
  overflow: hidden;
  padding: 30px 10px;
  min-height: 100vh;
  background-color: white;
  z-index: 0;
}
.today-matches-container .icon {
  font-size: 70px;
}
.connect-now-btn {
  position: fixed;
  right: 5%;
  bottom: 10%;
  z-index: 99;
  font-size: 10px;
  background: linear-gradient(to right, #26bd68, #066759);
  padding: 8px 20px;
  outline: transparent;
  border: transparent;
  color: white;
}
.today-match-user-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.today-match-user-img {
  position: relative;
  width: 25%;
  height: 320px;
}
.today-match-user-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.today-match-user-img .arrow {
  position: absolute;
  bottom: 5%;
  cursor: pointer;
}
.today-match-user-img .arrow .icon {
  font-size: 35px;
  color: #fff;
}
.today-match-user-img .left-arrow {
  left: 0;
}
.today-match-user-img .right-arrow {
  right: 0;
}
.today-match-user-details {
  width: 75%;
  padding: 0 20px;
}
.shd {
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
}
.today-match-tab button {
  padding: 10px 20px;
  background-color: #26bd68;
  outline: transparent;
  border: transparent;
  border-radius: 0;
  color: white;
  opacity: 0.7;
}
.today-match-tab button.active {
  opacity: 1;
}
.action-btns {
  display: flex;
  justify-content: flex-end;
}
.action-btns .prev-user,
.action-btns .next-user {
  cursor: pointer;
}
.action-btns .prev-user img,
.action-btns .next-user img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 100px;
}
.today-match-about button {
  border-radius: 100px;
  padding: 5px 15px;
  outline: transparent;
  border: 1px solid black;
  margin-right: 10px;
}
.like-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* ===================== LIKE PROFILE CONTAINER  */

.today-match-show-contact-container {
  background: linear-gradient(to right, #26bd68, #066759);
  display: flex;
  gap: 10px;
}
.today-match-show-contact-container .icon {
  background-color: #26bd68;
  color: #fff;
  padding: 10px;
  font-size: 45px;
  border-radius: 100px;
  margin-left: 15px;
  border: 2px solid #fff;
}
/* ========= */
.today-match-user-contact-container {
  display: flex;
  justify-content: center;
  padding: 10px;
  width: 100%;
  flex-wrap: wrap;
  background-color: #26bd672b;
  gap: 20px;
}
.today-match-user-contact-container button {
  width: 80%;
  background-color: #fff;
  font-size: 10px;
  border: transparent;
  outline: transparent;
  border-radius: 100px;
  padding: 8px 15px;
}

/* ========= */
.today-match-like-profile-container {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 10px;
}
.today-match-like-profile-container:hover {
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
}
.today-match-like-profile-container .icon {
  color: #26bd68;
  font-size: 40px;
}

@media screen and (max-width: 992px) {
  .today-matches-container {
    padding-bottom: 100px;
  }
  .today-match-user-img {
    width: 100%;
  }
  .action-btns {
    width: 100%;
    padding: 10px;
    padding-bottom: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: #26bd672b;
    padding: 10px;
  }
  .action-btns .prev-user img,
  .action-btns .next-user img {
    width: 40px;
    height: 40px;
  }
  .today-match-user-details {
    width: 100%;
    padding: 0;
  }
  .like-container {
    display: none;
  }
  .today-match-like-profile-container {
    margin-top: 0;
  }
}
