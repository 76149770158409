.complete-profile-container {
  background: linear-gradient(to right, #26bd68, #066759);
  padding: 50px 0;
}
.complete-profile-form {
  width: 60%;
  display: block;
  margin: 0 auto;
  padding: 30px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.continue-btn {
  background-color: #066759;
  padding: 10px 20px;
  border: transparent;
  outline: transparent;
  color: white;
  display: block;
  margin: 0 auto;
}
.phone-input-container {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

@media screen and (max-width: 992px) {
  .complete-profile-container {
    padding: 0;
  }
  .complete-profile-form {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 0;
  }
}
