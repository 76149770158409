.hero-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.register-form {
  padding: 50px 20px;
}
.register-btn {
  border-radius: 0;
  border: transparent;
  outline: transparent;
  padding: 10px 20px;
  background-color: #26bd68;
  color: white;
}
.register-btn:hover {
  background-color: #066759;
}
.hero-section-img {
  position: relative;
}
.hero-section-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.hero-heading {
  position: absolute;
  padding: 0 20px;
  bottom: 25px;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

@media screen and (max-width: 786px) {
}
