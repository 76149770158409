.my-photos-container {
  width: 60%;
  display: block;
  margin: 0 auto;
  background-color: white;
  padding: 50px 10px;
}

.photo-tab-btn {
  border-radius: 0;
  border: transparent;
  outline: transparent;
  padding: 10px 20px;
  background-color: #26bd68;
  color: white;
  opacity: 0.8;
}
.photo-tab-btn.active {
  opacity: 1;
}

.image-upload-container {
  background-color: #d0eadb;
  padding: 20px;
  border-radius: 10px;
}
.photos {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  gap: 10px;
}
.photo-image-holder {
  width: 25%;
  position: relative;
}
.photo-image-holder .delete-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 40px;
  cursor: pointer;
  color: red;
  scale: 1;
  transition: 0.3s all ease;
}
.photo-image-holder .delete-icon:hover {
  scale: 1.2;
}
.photos img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  cursor: pointer;
  border: 4px solid #26bd68;
}
/* ============ PHOTO SETTINGS  */
.photo-privacy-settings {
  min-height: 50vh;
  margin-bottom: 50px;
}
.photo-privacy-settings span {
  display: block;
  font-size: 14px;
  padding: 5px;
  border: 1px solid #26bd68;
  margin: 10px 0;
  cursor: pointer;
}
.photo-privacy-settings span:hover {
  background-color: #26bd68;
  color: #fff;
}
.photo-privacy-settings span.active {
  background-color: #26bd68;
  color: #fff;
}
.privacy-photo-demo {
  text-align: center;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px 0;
}

.privacy-photo-demo img {
  width: 70%;
  height: 300px;
  object-fit: cover;
}
@media screen and (max-width: 992px) {
  .my-photos-container {
    width: 100%;
    padding-bottom: 100px;
  }
  .photos {
    gap: 20px;
  }
  .photo-image-holder {
    width: 45%;
  }
  .photo-image-holder .delete-icon {
    display: block;
    font-size: 30px;
    top: 15px;
    left: 80%;
  }
  .photo-privacy-settings {
    text-align: center;
  }
  .privacy-photo-demo {
    margin-top: 20px;
  }
}
