.loading-container {
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader {
  width: 80px;
  height: 50px;
  position: relative;
}

.loader-text {
  position: absolute;
  top: 0;
  padding: 0;
  margin: 0;
  color: #066759;
  animation: text_713 3.5s ease both infinite;
  font-size: 0.8rem;
  letter-spacing: 1px;
}

.load {
  background-color: #26bd68;
  border-radius: 50px;
  display: block;
  height: 16px;
  width: 16px;
  bottom: 0;
  position: absolute;
  transform: translateX(64px);
  animation: loading_713 3.5s ease both infinite;
}

.load::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: #066759;
  border-radius: inherit;
  animation: loading2_713 3.5s ease both infinite;
}

@keyframes text_713 {
  0% {
    letter-spacing: 1px;
    transform: translateX(0px);
  }

  40% {
    letter-spacing: 2px;
    transform: translateX(26px);
  }

  80% {
    letter-spacing: 1px;
    transform: translateX(32px);
  }

  90% {
    letter-spacing: 2px;
    transform: translateX(0px);
  }

  100% {
    letter-spacing: 1px;
    transform: translateX(0px);
  }
}

@keyframes loading_713 {
  0% {
    width: 16px;
    transform: translateX(0px);
  }

  40% {
    width: 100%;
    transform: translateX(0px);
  }

  80% {
    width: 16px;
    transform: translateX(64px);
  }

  90% {
    width: 100%;
    transform: translateX(0px);
  }

  100% {
    width: 16px;
    transform: translateX(0px);
  }
}

@keyframes loading2_713 {
  0% {
    transform: translateX(0px);
    width: 16px;
  }

  40% {
    transform: translateX(0%);
    width: 80%;
  }

  80% {
    width: 100%;
    transform: translateX(0px);
  }

  90% {
    width: 80%;
    transform: translateX(15px);
  }

  100% {
    transform: translateX(0px);
    width: 16px;
  }
}

.admin-users-container {
  overflow: hidden;
  position: relative;
}
.table-container {
  min-height: 100vh;
}
.page-title {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  gap: 10px;
}
.page-title button {
  background-color: #fff;
  border: transparent;
  outline: transparent;
  color: #333;
  padding: 5px 10px;
}
.table-container {
  background-color: #fff;
  /* margin-top: 50px; */
  padding: 20px 10px;
}
.tools {
  display: flex;
  align-items: center;
  color: #000;
  margin-bottom: 20px;
  gap: 10px;
}
.tools select {
  padding: 5px;
  width: 200px;
}
.tools input {
  color: #333;
  padding: 5px;
  border: 1px solid grey;
}
.tools input:focus {
  outline: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
}
.user-table {
  overflow-x: scroll !important;
}
.user-table img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 100px;
}

.mail-container {
  position: absolute;
  padding: 50px;
  height: 100%;
  width: 100%;
  z-index: 9999;
  background-color: #fff;
  color: #333;
  scale: 0;
  transition: 0.3s all ease;
}
.mail-container.active {
  scale: 1;
}
.mail-container input,
.mail-container textarea {
  width: 100%;
}

@media screen and (max-width: 992px) {
  .table-container {
    overflow-x: scroll;
  }
  .user-table img {
    width: 100%;
    border-radius: 100px;
  }
}
