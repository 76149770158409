.how-it-works {
  padding: 80px 10px;
  width: 100%;
  background-color: white;
  overflow: hidden;
}

.how-it-works h3 {
  /* font-size: 40px; */
  font-weight: 600;
  width: 100%;
}

.how-it-works h3 span {
  color: #26bd68;
  font-size: 35px;
}

@media screen and (max-width: 992px) {
  .how-it-works {
    padding: 60px 20px;
    text-align: center;
  }
  .choose-better-container::before {
    /* background-image: url('../../svg/hero-mobile.svg'); */
    bottom: -780px;
  }
  .how-it-works h2 {
    font-size: 30px;
    line-height: 35px;
    text-align: center;
  }
}
