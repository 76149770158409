.parent-plan-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.plans-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 30px;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.plans-card {
  width: 20%;
  background-color: #fff;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  padding: 40px 20px;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
}
.plans-card .name {
  position: absolute;
  top: -10px;
  left: 20px;
  background-color: #066759;
  padding: 2px 15px;
  color: #fff;
  border-radius: 10px;
  font-size: 14px;
}
.plans-card:hover {
  background-color: #def4e9;
}
.plans-card.active {
  background-color: #def4e9;
  &:hover {
    background-color: #bce8d2;
  }
}
.plan-btn {
  background-color: #26bd68;
  color: #fff;
  outline: transparent;
  border: transparent;
  padding: 4px 15px;
  border-radius: 100px;
}
/* how-profile-will-look-container */
.how-profile-will-look-container {
  background-color: #fff;
  padding: 50px 0;
}
.how-profile-will-look-container .new-matches-users-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  padding: 20px;
}
.how-profile-will-look-container .new-match-users {
  width: 30%;
}
.how-profile-will-look-container .show-big {
  width: 33%;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
}
.premium-profile-container {
  width: 100%;
}
.profiles {
  width: 32% !important;
}
@media screen and (max-width: 992px) {
  .plans-container {
    padding-bottom: 50px;
  }
  .plans-card {
    width: 100%;
  }
  .how-profile-will-look-container {
    padding-bottom: 100px;
  }
  .how-profile-will-look-container .new-match-users {
    width: 100%;
    scale: 1;
  }

  .profiles {
    width: 100% !important;
  }
}
