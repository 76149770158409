.dashboard-container {
  padding: 30px 0;
}
.under-verification {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
}
.terms span {
  font-size: 12px;
  display: block;
}
.terms a {
  color: #066759;
  margin: 0 4px;
}
.dashboard {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.photo-container {
  width: 20%;
  background-color: #fff;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}
.photo {
  width: 100%;
  height: 250px;
  position: relative;
}
.loading {
  position: relative;
}

.loading.active::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #b8b8b8;
}

.loading.active::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  background-image: linear-gradient(
    to left,
    transparent,
    rgba(255, 255, 255, 0.5),
    transparent
  );

  animation: loading 1s infinite;
}

@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}

/* .photo .icon {
  position: absolute;
  bottom: 5px;
  right: 5px;
  color: red;
  scale: 1;
  animation: iconScale 1s ease infinite;
} */

@keyframes iconScale {
  0% {
    scale: 1;
  }
  50% {
    scale: 1.2;
  }
  100% {
    scale: 1;
  }
}
.photo .arrow {
  position: absolute;
  bottom: 5px;
  padding: 5px;
  margin: 0;
  color: white;
}
.photo .arrow .icon {
  font-size: 30px;
  cursor: pointer;
}
.photo .arrow .icon:hover {
  opacity: 0.8;
}
.photo .left-arrow {
  left: 0;
}
.photo .right-arrow {
  right: 0;
}

/* .photo::before {
  content: "<";
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 30px;
  height: 30px;
  font-size: 30px;
  background-color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
} */
.photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.edit-profile,
.upgrade,
.mobile-verified {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.edit-profile:hover,
.upgrade:hover,
.mobile-verified:hover {
  background-color: #eee;
}
.edit-profile a,
.upgrade a {
  color: #26bd68;
  font-weight: 500;
  transition: 0.3s all ease;
}
.edit-profile a:hover,
.upgrade a:hover {
  text-decoration: underline !important;
}
.activity-container {
  width: 50%;
}
.promotion-container {
  width: 25%;
}

.verified-icon .icon {
  color: #26bd68;
}
/* =============== ACTIVITY SUMMARY ===============  */
.summaries-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.summary-card {
  width: 33%;
  height: 80px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: white;
  margin-bottom: 10px;
  padding: 10px;
  text-align: center;
  transition: 0.3s all ease;
  cursor: pointer;
}
.summary-card:hover {
  background-color: #eee;
}
.summary-counter span {
  font-size: 25px;
  font-weight: bold;
  color: grey;
}
.summary-title span {
  font-weight: 200;
  font-size: 14px;
}
/* =============== ACTIVITY SUMMARY ===============  */
/* =============== PROMOITON CONTAINER ===============  */
.promotion-container {
  width: 25%;
  background-color: #fff;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}
.promotion-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* =============== PROMOITON CONTAINER ===============  */
/* =============== MOBILE CONTAINER ===============  */
.mobile-dashboard-container {
  background-color: #f7f7f7;
}
.mobile-photo {
  width: 100%;
  height: 250px;
}
.mobile-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.mobile-dashboard-menu {
  padding: 10px;
}
.mobile-dashboard-menu ul li a {
  color: #333;
}

/* =============== MOBILE CONTAINER ===============  */
/* =============== IMPROVE PROFILE DASHBOARD ===============  */
.improve-profile-container {
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.improve-profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #def4e9;
  padding: 20px;
}
.improve-profile button {
  outline: transparent;
  border: transparent;
  background-color: #26bd68;
  color: #fff;
  padding: 8px 10px;
}
.today-match-btn {
  padding: 2px 15px !important;
  margin-top: 5px;
  background: linear-gradient(to right, #066759, #26bd68);
  border-radius: 100px;
}
.profile-range-status {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 10px;
}
.profile-range-status .range {
  height: 10px;
  width: 100%;
  background-color: #066759;
}
.profile-range-status .range .percentage {
  height: 100%;
  background-color: #26bd68;
}
.profile-complete-success {
  display: flex;
  text-align: center;
}
/* =============== IMPROVE PROFILE DASHBOARD ENDS ===============  */
/* =============== MOBILE CONTAINER ===============  */

/* =============== MEDIA QUERY ===============  */
@media screen and (max-width: 992px) {
  .dashboard-container {
    padding: 0;
    padding-bottom: 80px;
  }
  .photo-container {
    width: 100%;
  }
  .activity-container {
    width: 100%;
    padding: 10px;
  }
  .promotion-container {
    width: 100%;
  }
  .summary-card {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 20px;
  }
}
/* =============== MEDIA QUERY ===============  */
