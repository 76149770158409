.sidemenu-container {
  position: fixed;
  top: 0;
  left: -100%;
  width: 80%;
  height: 100vh;
  transition: all 0.3s ease;
  z-index: 20;
}
.sidemenu {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 200px;
  position: relative;
  background-color: #fff;
}
.sidemenu .close-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 35px;
}

.sidemenu .logout {
  color: #26bd68;
  cursor: pointer;
}
.sidemenu .logout:hover {
  color: #333;
}

.sidemenu .logout .icon {
  font-size: 25px;
  margin-left: 5px;
}
.sidemenu-img {
  width: 80px;
  height: 80px;
  border-radius: 100px;
  object-fit: cover;
  border: 3px solid #26bd68;
  display: block;
  margin: 0 auto;
}
.active {
  left: 0;
}

.sidemenu-container ul {
  padding: 0;
  width: 100%;
}
.sidemenu-container ul li {
  list-style: none;
  margin: 20px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
}
.sidemenu ul li a {
  text-decoration: none;
  color: black;
  font-size: 20px;
}
.sidemenu-action-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.sidemenu-action-btn .request-btn {
  background: linear-gradient(to right, #066759, #26bd68);
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  outline: transparent;
  border: transparent;
  padding: 8px 20px;
  &:hover {
    opacity: 0.8;
  }
}
.sidemenu-social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}
.sidemenu-social-icons .icon {
  font-size: 30px;
  color: #066759;
}
@media screen and (max-width: 376px) {
  .sidemenu-container {
    width: 70%;
  }
  .sidemenu-action-btn .request-btn {
    font-size: 12px;
  }
}
