.contact-page-container {
  overflow: hidden;
  background-color: #fff;
}
.contact-image {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact-page-container img {
  width: 80%;
  height: 100%;
  object-fit: cover;
}
.contact-form {
  padding: 50px 20px;
}
.contact-form textarea {
  resize: none;
}

.contact-address-email {
  padding: 50px 20px;
  background-color: #def4e9;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
.c-address {
  width: 30%;
  padding: 40px;
  background-color: #ffffff;
  border-radius: 20px;
  border: 2px solid #ffffff;
  transition: all 0.4s;
  &:hover {
    background-color: #def4e9;
    border: 2px solid #000000;
  }
}

@media screen and (max-width: 992px) {
  .contact-form {
    padding: 50px;
  }
  .c-address {
    width: 100%;
  }
}
