.features-container {
  padding: 100px;
  text-align: center;
  padding-top: 50px;
  background-color: #fff;
}
.features-section {
  display: flex;
  width: 100%;
}
.features {
  width: 24%;
  text-align: center;
  margin: 0 30px;
  margin-top: 75px;
}
.features-section .features:nth-child(2),
.features-section .features:nth-child(4) {
  margin-top: 30px;
}
.features img {
  width: 40%;
  border-radius: 100px;
  margin-bottom: 30px;
}
.features h5 {
  font-weight: 600;
}
.f-line {
  margin-top: 40px !important;
  height: 3px;
  width: 70%;
  display: block;
  margin: 0 auto;
  background-color: #26bd68;
}

@media screen and (max-width: 786px) {
  .features-section {
    flex-wrap: wrap;
  }
  .features {
    width: 100%;
    text-align: center;
    margin: 0;
    padding: 20px;
  }
  .features-container {
    padding: 50px 20px;
  }
}
