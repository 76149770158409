.contact-container {
  width: 60%;
  display: block;
  margin: 0 auto;
  background-color: white;
  padding: 50px 10px;
  min-height: calc(100vh - 130px);
}
.contact-title {
  padding: 10px;
  background-color: #eee;
}
.contact-container .request-btn {
  background-color: #26bd68;
  color: #fff;
  margin: 20px 0 10px 0;
}
@media screen and (max-width: 992px) {
  .contact-container {
    width: 100%;
    padding: 20px 10px;
  }
}
