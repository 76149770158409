.edit-profile-container {
  width: 60%;
  display: block;
  margin: 0 auto;
  padding: 30px 10px;
  position: relative;
}
.page-heading {
  font-size: 16px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.basic-info {
  padding: 10px;
  background-color: #fff;
}
.fields-container {
  height: auto;
}
.fields-container.off {
  display: none;
}
.basic-info .title span {
  font-weight: 500;
}
@media screen and (max-width: 992px) {
  .edit-profile-container {
    width: 100%;
    padding-bottom: 100px;
  }
  .page-heading {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .page-heading span {
    width: 100%;
  }
  .update-btn {
    position: fixed;
    bottom: 0 !important;
    left: 0;
    width: 100% !important;
    z-index: 9999999999;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
